(function ($) {

    $('#js-diaries-load').on('click', function () {
        var $btn = $(this);
        $.ajax({
            type: "post",
            url: "/wp-admin/admin-ajax.php",
            data: {action:'get_diaries'},
            beforeSend: function () {
                $btn.find('.btn-text').text('Loading...');
                $btn.addClass('disabled');
            },
            success: function (response) {
                console.log(response);
                $('#js-diaries-result .grid').append(response);
                $('#js-diaries-result').show(300);
                $btn.hide();

            }
        });

        return false;
    });

})(jQuery);