//import 'accordionjs';

(function ($) {

    // var $acc = $(".accordion").accordionjs({
    //     activeIndex : false,
    //     closeOther : false,
    //     slideSpeed: 300,
    //     openSection: function () {
    //         var id = $(this).attr('id');
    //         $('#' + id).find('.tab-headings div:first').trigger('click');
    //     }
    // });


    $('.c-accordion li').each(function () {
        var $parent = $(this);
        var $ancestor = $parent.parent();
        var $header = $parent.find('.c-accordion__header')

        $header.on('click', function () {
            var $e = $(this);
            var $li = $e.closest('li');
            if ($li.hasClass('active')) {
                $li.removeClass('active');
            } else {
                $ancestor.find('li.active').removeClass('active');
                $li.addClass('active');
                $('html, body').animate({
                    scrollTop: $li.offset().top - $('.c-section-nav').height()
                }, {duration: 300, easing: 'linear'}); 
            }
            return false;
        });
    });

    $('.c-tab').each(function () {
        var $parent = $(this);
        var $header = $parent.find('.c-tab__nav a')

        $header.on('click', function () {
            var $e = $(this);
            $parent.find('.active').removeClass('active');
            
            $e.addClass('active');
            $($e.attr('href')).addClass('active');

            return false;
        });
    });

    var openTimer;

    $('.c-section-nav a').on('click', function () {

        var $e = $(this);
        $e.closest('.c-section').find('.c-accordion li.active').removeClass('active');

        $('html, body').animate({
            scrollTop: $($e.attr('href')).offset().top - $('.c-section-nav').height()
        }, {duration: 300, easing: 'linear', complete: function () {
            $('li' + $e.attr('href')).addClass('active');
        }}); 
        return false;

    });




    var $window = $(window);
    var $section = $('.c-section');
    var $hideDiv = $('#masthead');

    function checkScroll() {
        var sectionTop = $section.offset().top;
        var sectionBottom = sectionTop + $section.outerHeight();
        var windowTop = $window.scrollTop();
        var windowBottom = windowTop + $window.height();

        if (windowBottom > sectionTop && windowTop < sectionBottom) {
            $hideDiv.hide();
        } else {
            $hideDiv.show();
        }

        $('.c-section__section').each(function() {
            var sectionTop = $(this).offset().top - $('.c-section-nav').height(); // Adjust for accuracy
            var sectionBottom = sectionTop + $(this).outerHeight();
            var sectionId = $(this).attr('id');

            if (windowTop >= sectionTop && windowTop < sectionBottom) {
                $('.c-section-nav a.active').removeClass('active');
                $('.c-section-nav a[href="#' + sectionId + '"]').addClass('active');
            }
        });
    }

    if ($('.c-accordion').length) {
        $window.on('scroll', checkScroll);
        checkScroll();
    }

})(jQuery);