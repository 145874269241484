(function ($) {

    jQuery(".left-swiper .swiper-container").each(function () {
        var swiper = this;
        var swiper_id = jQuery(swiper).data("swiper-id");

        var $parent = $(this).closest('.left-swiper');
        $parent.find('.slider-swiper-navigation').prepend('<div class="pageno" id="pageno-' + swiper_id + '"></div>');
      
        var params = {
            loop: false,
            slidesPerView: 1.1,
            speed: 1000,
            spaceBetween: 16,
            preventClicks: true,
            preventClicksPropagation: true,
            observer: true,
            observeSlideChildren: true,
            observeParents: true,
            allowTouchMove: true,
            navigation: {
                nextEl: '.swiper-' + swiper_id + ' .general-slider-swiper-next-button',
                prevEl: '.swiper-' + swiper_id + ' .general-slider-swiper-prev-button',
              },
              breakpoints: {
                992: {
                  slidesPerView: 2.2,
                    spaceBetween: 40
                }
            },
            on: {
                init: function() {
                    updateSlideNumber(this, swiper_id);
                },
                slideChange: function() {
                    updateSlideNumber(this, swiper_id);
                },
            }
        };
          
        var heroSwiper = new Swiper(swiper, params);
        
    });

    function updateSlideNumber(swiper, swiper_id) {
        var currentSlide = swiper.realIndex + 1;
        var totalSlides = swiper.slides.length;
        var text = (currentSlide < 10 ? '0' + currentSlide : currentSlide) + ' / ' + 
        (totalSlides < 10 ? '0' + totalSlides : totalSlides);

        document.getElementById('pageno-' + swiper_id).textContent = text;
            
    }


    initStoriesCarousel();

    function initStoriesCarousel() {

        $('.stories-section').each(function () {

            var $parent = $(this);
            var id = $parent.find('.mainswiper').data('id');

            var thumbSwiper = new Swiper("#thumbswiper-" + id, {
                slidesPerView: 'auto',
                spaceBetween: 0,
                centeredSlides: true,
                loop:true,
                mousewheel: true,
                slideToClickedSlide: true,
                // navigation: {
                //     prevEl: ".swiper-button-prev--ow",
                //     nextEl: ".swiper-button-next--ow",
                // },
            });
        
        
            var mainSwiper = new Swiper("#mainswiper-" + id, {
                slidesPerView : 1,
                centeredSlides: true,
                spaceBetween: 0,
                loop:true,
                loopedSlides: 10,
                autoHeight  : true, 
                fadeEffect : {
                    "crossFade": true
                },
                effect: "fade"
            });

            mainSwiper.controller.control = thumbSwiper;
            thumbSwiper.controller.control = mainSwiper;


            
        
        
            var mainSwiperMobile = new Swiper("#mainswiper-mobile-" + id, {
                slidesPerView : 1,
                centeredSlides: true,
                spaceBetween: 24,
                loop:true,
                loopedSlides: 10,
                autoHeight  : true
            });



        });

    }
    
})(jQuery);