import magnificPopup from 'magnific-popup';

(function ($) {

    // Video Module
    $('.c-video').each(function (x) {
        var $e = $(this);
        var $button = $e.find('.c-video__play');
        var $video = $e.find('video');

        $video.attr('id', 'video-' + x);


        $e.on('click', function () {
            if ($e.hasClass('playing')) {
                $video[0].pause();
                $e.removeClass('playing');
            } else {
                $video[0].play();
                $e.addClass('playing');
            }
            return false;
        });

    });


    // Autoplay cover video
     // Function to handle video play and pause based on visibility
     function handleVideoPlayback(entries, observer) {
        entries.forEach(entry => {
            const video = $(entry.target).find('.c-cover-video video').get(0);
            if (video) {
                if (entry.isIntersecting) {
                    video.play();
                } else {
                    video.pause();
                }
            }
        });
    }

    // Create an intersection observer
    const observer = new IntersectionObserver(handleVideoPlayback, {
        threshold: 0.5 // Adjust the threshold as needed
    });

    // Observe each .section-bg element
    $('.section-bg').each(function () {
        observer.observe(this);
    });

    $('.link-video').magnificPopup({
        type: 'iframe'
    });
    
})(jQuery);